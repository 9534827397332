/* v8 ignore start */
import * as Sentry from "@sentry/sveltekit";
import { BROWSER } from "esm-env";

export const sentry = Sentry;

export function initSentry(dsn: string) {
  sentry.init({
    dsn,
    tracesSampleRate: 0.5,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    denyUrls: [/^https?:\/\/localhost(:\d+)?/],
  });

  if (BROWSER) {
    import("@sentry/sveltekit").then((lazySentry) => {
      sentry.addIntegration(lazySentry.replayIntegration());
      sentry.addIntegration(lazySentry.browserProfilingIntegration());
    });
  } else {
    // TODO: Fix binary location in Vercel https://github.com/getsentry/profiling-node/issues/171
    // import("@sentry/profiling-node").then(({ nodeProfilingIntegration }) => {
    //   sentry.addIntegration(nodeProfilingIntegration() as Integration);
    // });
  }
}
/* v8 ignore end */
